import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import './Modal.css';

const Modal = forwardRef(({children, title}, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const modalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        openModal: () => setIsOpen(true),
        closeModal: () => setIsOpen(false)
    }));

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && isOpen) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal" onClick={() => setIsOpen(false)}>
            <div className="modal_inner" onClick={(e) => e.stopPropagation()} ref={modalRef}>
                <div className="modal_inner_header">{title}</div>
                <div className="modal_inner_container">
                    {children}
                </div>
            </div>
        </div>
    );
});

export default Modal;
