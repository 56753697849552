import React, { useEffect, useRef, useState } from "react";
import Header from "../Shared/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../Shared/Dropdown";
import SearchStyleButton from "../Shared/SearchStyleButton";
import TaskCard from "./TaskCard";
import "./Tasks.css";
import Modal from "../Shared/Modal";
import DatePicker from "react-datepicker";
import axios from "axios";
import { useAuth } from '../../Auth/AuthContext';

const Tasks = () => {
  // Access levels
  const { user } = useAuth();

  const canAddTasks = user.userLevel <= 3; // Levels 0, 1, 2, 3 can add/delete

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const modalRef = useRef(null);
  const [tasks, setTasks] = useState([]);
  const [departments1, setDepartments] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Alle");
  const [selectedDeptFilter, setSelectedDeptFilter] = useState("Alle");

  const refreshTasks = async () => {
    try {
      const response = await axios.get("/api/tasks");
      setTasks(response.data);
    } catch {
      alert("Opgaver blev ikke hentet korrekt");
    }
  };

  const refreshDepartments = async () => {
    try {
      const response = await axios.get("/api/departments");
      setDepartments(response.data);
    } catch {
      alert("Afdelinger blev ikke hentet korrekt");
    }
  };

  const fetchAllData = () => {
    refreshTasks();
    refreshDepartments();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const department_names = departments1.map(
    (departmentObj) => departmentObj.name
  );

  const addTask = async (newTask) => {
    try {
      const response = await axios.post("/api/add-task", newTask, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setTasks([...tasks, response.data]);
        modalRef.current.closeModal();
      }
    } catch {
      alert("Fejl ved indsættelse af opgave");
    }
  };

  const handleSubmit = async () => {
    const newTask = {
      title,
      description,
      department: selectedDepartment,
      fixed: false,
      date_created: new Date(),
      end_date: new Date(endDate),
    };

    addTask(newTask);
  };

  const handleDateChange = (date) => {
    setEndDate(date);
  };

  const updateCheck = async (id, fixed) => {
    try {
      const response = await axios.patch(`/api/tasks/${id}`, { fixed: !fixed });
      if (response.status === 200) {
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task._id === id ? { ...task, fixed: !fixed } : task
          )
        );
      }
    } catch (error) {
      alert("Error updating task: " + error.message);
    }
  };

  useEffect(() => {
    console.log("Tasks state updated: ", tasks);
  }, [tasks]);

  const filteredTasks = tasks.filter((task) => {
    const matchesSearchTerm = task.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesStatus =
      selectedStatus === "Alle" ||
      (selectedStatus === "Færdig" && task.fixed) ||
      (selectedStatus === "Ikke færdig" && !task.fixed);
    const matchesDepartment =
      selectedDeptFilter === "Alle" || task.department === selectedDeptFilter;
    return matchesSearchTerm && matchesStatus && matchesDepartment;
  });

  return (
    <div className="main_inner">
      <Header title={"Opgaver"}>
        <div className="search_container">
          <FontAwesomeIcon icon={faSearch} className="search_icon" />
          <input
            type="text"
            placeholder="Søg efter opgave..."
            className="search_input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Dropdown
            init={"Status"}
            style={{ marginRight: "10px", width: "200px" }}
            options={["Alle", "Færdig", "Ikke færdig"]}
            onSelect={(status) => setSelectedStatus(status)}
          />
          <Dropdown
            init={"Afdeling"}
            options={["Alle", ...department_names]}
            style={{ width: "200px" }}
            onSelect={(option) => setSelectedDeptFilter(option)}
          />
        </div>
        {canAddTasks && (
          <div
            className="search_container"
            style={{ marginLeft: "10px", height: "60px" }}
            onClick={() => {
              modalRef.current.openModal();
            }}
          >
            <SearchStyleButton title={"Tilføj opgave"} />
          </div>
        )}
      </Header>
      <div className="main_inner_content">
        <Modal ref={modalRef} title={"Tilføj opgave"}>
          <div className="add_task_title">
            <p>Titel</p>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Skriv titel..."
              className="search_input"
            />
          </div>
          <div className="add_task_desc">
            <p>Beskrivelse</p>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Beskriv hændelse..."
              className="search_input"
              rows="1"
            />
          </div>
          <div className="add_task_dds_container">
            <div className="urgency">
              <span>Senest udført d.</span>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                customInput={
                  <button className="button_date">
                    {endDate.toLocaleDateString("en-GB")}
                  </button>
                }
                selected={endDate}
                onChange={handleDateChange}
              />
            </div>
            <Dropdown
              init={"Afdeling"}
              options={["Alle", ...department_names]}
              onSelect={(option) => {
                setSelectedDepartment(option);
              }}
            />
          </div>
          <div className="reperation_submit_container">
            <button onClick={handleSubmit}>Tilføj opgave</button>
          </div>
        </Modal>
        <div className="tasks_card_container">
          {filteredTasks
            .slice()
            .reverse()
            .map((task) => (
              <TaskCard
                key={task._id}
                taskId={task._id}
                updateCheck={() => updateCheck(task._id, task.fixed)}
                checkmark={task.fixed}
                header={task.title}
                description={task.description}
                loca={task.department}
                date={task.date_created}
                haste={task.end_date}
                setTasks={setTasks}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Tasks;
