import React from 'react';
import './SearchStyleButton.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";

const SearchStyleButton = ({ title }) => {
    return (
        <div style={{display:"flex"}}className="search_button">
            <FontAwesomeIcon icon={faPlus} className="plus_icon" />
            <p style={{marginLeft:"auto"}}><b>{title}</b></p>
        </div>
    );
};

export default SearchStyleButton;
