import React, { useState } from 'react';
import './Header.css';
import NumberFormat from "react-currency-format";

const Excel = ({ columns, rows, onRowClick }) => {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const handleRowClick = (row, index) => {
        setSelectedRowIndex(index);
        onRowClick(row);
    };

    return (
        <div className="add_rep_invoice">
            <table>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{column.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr 
                        key={rowIndex} 
                        style={rowIndex === selectedRowIndex ? { backgroundColor: 'rgba(0, 0, 0, 0.3)' } : {}}
                        onClick={() => handleRowClick(row, rowIndex)}
                        >
                            {row.map((cell, colIndex) => (
                                <td key={colIndex}>
                                    {columns[colIndex].type === 'id' ? (
                                        <span>
                                            {cell.toString().slice(-6)}
                                        </span>
                                    ) : columns[colIndex].type === 'currency' ? (
                                        <NumberFormat value={cell} displayType={'text'}
                                                      thousandSeparator={'.'} decimalSeparator={','}
                                                      prefix={'DKK '} />
                                    ) : (
                                        cell
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Excel;
