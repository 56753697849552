import React from 'react';
import Logo from '../../assets/lisbeth-stor.png';
import './Header.css'

const Header = ({ title, children }) => {
    return (
        <div className="main_inner_top">
            <div className="main_inner_top_interact">
                <p className="main_inner_top_interact_header"><b>{title}</b></p>
                <div className="main_inner_top_interact_content">
                    {children}
                </div>
            </div>
            <img src={Logo} alt="Logo"/>
        </div>
    );
};

export default Header;
