import React from 'react';
import './SplitInner.css'

const SplitInner = ({ children }) => {
    const childrenArray = React.Children.toArray(children);
    const leftChild = childrenArray[0];
    const rightChild = childrenArray[1];
    return (
        <div className="split_inner_container">
            <div className="split_inner_container_split">
                {leftChild}
            </div>
            <div className="split_inner_container_split">
                {rightChild}
            </div>
        </div>

    );
};

export default SplitInner;
