import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faImages} from "@fortawesome/free-regular-svg-icons";

const FileUpload = ({ onFileChange, previewUrl }) => {
    return (
        <div id="annotation_upload" className="search_container" style={{borderRadius:"5px"}}>
            <input
                type="file"
                id="file"
                onChange={onFileChange}
                accept="image/*"
                style={{ display: 'none' }}
            />
            <label htmlFor="file" style={{ cursor: 'pointer', height:"100%" }}>
                {previewUrl ? (
                    <img src={previewUrl} alt="Preview" style={{ height: '100%', borderRadius: '5px' }} />
                ) : (
                    <FontAwesomeIcon icon={faImages} style={{color:"white", fontSize:"30px", width:"120px"}} />
                )}
            </label>
        </div>
    );
}

export default FileUpload;
