import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from '../../assets/lisbeth-stor.png'; // Ensure you have a logo in your assets

const InvoicePdf = (selectedInvoice) => {
    const generatePdf = () => {
        if (!selectedInvoice) return;

        const doc = new jsPDF();

        // Load logo image and get its dimensions
        const img = new Image();
        img.src = logo;
        img.onload = () => {
            const originalWidth = img.width;
            const originalHeight = img.height;

            // Set the maximum width and height for the logo
            const maxWidth = 50;
            const maxHeight = 30;

            // Calculate the new dimensions while maintaining the aspect ratio
            let width, height;
            if (originalWidth / originalHeight > maxWidth / maxHeight) {
                width = maxWidth;
                height = (originalHeight / originalWidth) * maxWidth;
            } else {
                height = maxHeight;
                width = (originalWidth / originalHeight) * maxHeight;
            }

            // Add logo with the calculated dimensions
            doc.addImage(logo, 'PNG', 14, 10, width, height);

            // Add company details
            doc.setFontSize(12);
            doc.text('Lisbeths Køreskole', 140, 15);
            doc.text('CVR: 3603 9175', 140, 20);
            doc.text('Vranderupvej 15', 140, 25);
            doc.text('Kolding, 6000', 140, 30);

            // Add title
            doc.setFontSize(20);
            doc.text('Faktura', 14, 50);

            // Add Invoice Number and Date
            doc.setFontSize(12);
            doc.text(`Faktura ID: ${selectedInvoice._id}`, 14, 60);
            doc.text(`Dato: ${new Date(selectedInvoice.date).toLocaleDateString()}`, 14, 65);

            // Add Department
            doc.text(`Afdeling: ${selectedInvoice.department}`, 14, 70);

            // Add table
            doc.autoTable({
                startY: 80,
                head: [['Stk.', 'Beskrivelse', 'Stk. Pris', 'Total']],
                body: selectedInvoice.items.map(item => [
                    item.quantity,
                    item.description,
                    `DKK ${item.unit_price}`,
                    `DKK ${item.total}`
                ]),
                headStyles: {
                    fillColor: [227, 6, 19], // Red color
                    textColor: [255, 255, 255], // White text
                    fontStyle: 'bold'
                }
            });

            // Add Total, VAT, and Total incl. VAT
            const finalY = doc.previousAutoTable.finalY + 10;
            doc.text(`Subtotal: DKK ${selectedInvoice.total_ex_vat}`, 140, finalY);
            doc.text(`25,00 % moms: DKK ${selectedInvoice.vat}`, 140, finalY + 8);
            doc.text(`Total: DKK ${selectedInvoice.total_incl_vat}`, 140, finalY + 16);

            // Save the PDF
            doc.save(`Faktura_${selectedInvoice._id}.pdf`);
        };
    };

    return generatePdf;
}

export default InvoicePdf;
