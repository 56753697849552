import React, { useRef } from "react";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../Shared/Modal";
import { useAuth } from '../../Auth/AuthContext';

const formatDate = (date) => {
  if (!date) return "Not specified";
  const dateObject = new Date(date);
  return dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const calculateDaysRemaining = (date) => {
  if (!date) return { text: "Not specified", color: "white" };
  const dateObject = new Date(date);
  const now = new Date();
  const differenceInTime = dateObject.getTime() - now.getTime();
  let differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  if (differenceInDays === 0) {
    differenceInDays = -1;
  }

  let color = "white";
  let text = "udløbet";

  if (differenceInDays > 0) {
    text = `${differenceInDays} dage tilbage`;
    if (differenceInDays > 10) {
      color = "rgb(53, 178, 53)";
    } else if (differenceInDays <= 10 && differenceInDays > 0) {
      color = "rgb(178, 157, 53)";
    }
  } else if (differenceInDays < 0) {
    color = "rgb(178, 53, 53)";
  }

  return { text, color };
};

const HasteInfo = ({ date }) => {
  const formattedDate = formatDate(date);
  const { text, color } = calculateDaysRemaining(date);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#4A4E69",
        alignItems: "center",
        height: "100%",
        aspectRatio: "1",
        borderRadius: "5px",
      }}
    >
      <p
        style={{
          textAlign: "center",
          fontSize: "15px",
          marginBottom: "10px",
          color: "white",
        }}
      >
        Senest udført:
        <br />
        {formattedDate}
      </p>
      <p
        style={{
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold",
          color,
        }}
      >
        {text}
      </p>
    </div>
  );
};

const TaskCard = ({
  updateCheck,
  checkmark,
  header,
  description,
  loca,
  date,
  haste,
  taskId,
  setTasks,
}) => {
  // Access levels
  const { user } = useAuth();

  const canDeleteTasks = user.userLevel <= 3; // Levels 0, 1, 2, 3 can add/delete
  const canSetTasksFinished = user.userLevel <= 1; // Levels 0 and 1 can set tasks to finished
  const modalRef = useRef(null);

  const handleDeleteTask = async (taskId, setTasks) => {
    try {
      // Delete task
      const response = await fetch(`/api/delete-task/${taskId}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Failed to delete task");

      modalRef.current.closeModal();

      setTasks((prevTasks) => prevTasks.filter((task) => task._id !== taskId));
    } catch {
      console.error("Fejl ved sletning af opgave");
    }
  };

  return (
    <div className="history_concrete_card_container">
      <HasteInfo date={haste} />
      <div className="history_concrete_card_data_container">
        <div className="history_concrete_card_data_header">
          <div className="history_concrete_card_data_header_data">
            <p>{header}</p>
            <p>{loca}</p>
          </div>
          <div className="history_concrete_card_data_header_date">
            <p>{formatDate(date)}</p>
            {checkmark !== null && (
              <FontAwesomeIcon
                className="gps_icon"
                style={{ color: checkmark ? "rgb(53, 178, 53)" : "#17191E" }}
                icon={faCheck}
                onClick={canSetTasksFinished ? updateCheck : undefined}
              />
            )}
            {canDeleteTasks && (
              <FontAwesomeIcon
                icon={faXmark}
                style={{
                  color: "rgb(178, 53, 53)",
                  cursor: "pointer",
                  marginRight: "5px",
                }}
                onClick={() => {
                  modalRef.current.openModal();
                }}
              />
            )}
          </div>
        </div>
        <div className="history_concrete_card_data_description">
          <span>{description}</span>
        </div>
      </div>
      <Modal ref={modalRef} title={"Er du sikker på sletning af opgaven?"}>
        <div id="modal_inner_fix">
          <div>
            <div className="reperation_submit_container">
              <button
                onClick={() => {
                  handleDeleteTask(taskId, setTasks);
                }}
                style={{ color: "rgb(178, 53, 53)" }}
              >
                SLET OPGAVE
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TaskCard;
