import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import './Main.css';
import Dashboard from './Dashboard/Dashboard';
import Tasks from './Tasks/Tasks';
import Invoices from './Invoices/Invoices';
import ConcreteMC from './Dashboard/ConcreteMC';
import Admin from './Admin/Admin';
import Login from '../Login';  // Ensure the login component is imported
import ProtectedRoute from '../Auth/ProtectedRoute';

const Main = () => {
    const location = useLocation();

    // Check if the current route is the login page
    const isLoginPage = location.pathname === "/login";

    return (
        <div id="main_layout">
            {/* Render Navbar on all pages except the login page */}
            {!isLoginPage && (<Navbar />)} 
            <div id="main_content">
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<ProtectedRoute requiredLevel={2}><Dashboard /></ProtectedRoute>} />
                    <Route path="/dashboard" element={<ProtectedRoute requiredLevel={3}><Dashboard /></ProtectedRoute>} />
                    <Route path="/tasks" element={<ProtectedRoute requiredLevel={3}><Tasks /></ProtectedRoute>} />
                    <Route path="/invoices" element={<ProtectedRoute requiredLevel={2}><Invoices /></ProtectedRoute>} />
                    <Route path="/admin" element={<ProtectedRoute requiredLevel={0}><Admin /></ProtectedRoute>} />
                    <Route path="/invoices/:invoiceNumber" element={<ProtectedRoute requiredLevel={2}><Invoices /></ProtectedRoute>} />
                    <Route path="/dashboard/mc/:cardId" element={<ProtectedRoute requiredLevel={3}><ConcreteMC /></ProtectedRoute>} />
                    <Route path="*" element={<ProtectedRoute requiredLevel={2}><Dashboard /></ProtectedRoute>} />
                </Routes>
            </div>
        </div>
    );
}

export default Main;
