import React, { useEffect, useRef, useState } from 'react';
import './Dropdown.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Dropdown = ({ options, onSelect, init, style}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(init);
    const dropdownRef = useRef(null);

    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onSelect(option);
        setIsOpen(false);
    };


    return (
        <div style={style} className="dropdown_container" ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="dropdown_header">
                {selectedOption}
                <span className="dropdown_arrow">
                    <FontAwesomeIcon icon={faChevronDown} />
                </span>
            </div>
            {isOpen && (
                <div className="dropdown_list">
                    {options.map((option, index) => (
                        <div className="dropdown_item" key={index} onClick={() => handleOptionClick(option)}>
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
