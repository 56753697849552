import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Auth/AuthContext';  // Adjusted the import path
import './Login.css';

function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('/login', { username, password });
            if (response.data.status === 'ok') {
                // Pass user data to login function
                const userData = {
                    username: response.data.username,
                    userLevel: response.data.userLevel,
                };
                login(userData);
                
                // Redirect based on user level
                if (userData.userLevel === 3) {
                    navigate('/main/tasks'); // Redirect to Tasks (Opgaver) for level 3 users
                } else {
                    navigate('/main/dashboard'); // Redirect to Dashboard for other levels
                }
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert('Invalid credentials. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleLogin} className="login-form">
                <h2>Login</h2>
                <div>
                    <label>
                        Username:
                        <input type="text" value={username} onChange={e => setUsername(e.target.value)} required/>
                    </label>
                </div>
                <div>
                    <label>
                        Password:
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} required/>
                    </label>
                </div>
                <button type="submit">Log In</button>
            </form>
        </div>
    );
}

export default LoginPage;
