import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from '../Auth/AuthContext';
import Login from '../Login';
import Main from '../Main/Main'

const Router = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate replace to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/main/*" element={
                            <Main />
                    } /> 
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default Router;