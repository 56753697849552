import React, { useRef } from "react";
import "./Dashboard.css";
import "./ConcreteMC.css";
import {
  faArrowRight,
  faXmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../Shared/Modal";
import axios from "axios";
import { useAuth } from "../../Auth/AuthContext";

const HistoryCard = ({
  header,
  description,
  loca,
  prevLoca,
  date,
  imgpath,
  currentKM,
  id,
  motorbikeId,
  setBikeData,
  bikeRegNumber,
  onEdit,
  type,
  invoiceId,
}) => {
  // User Group Rights
  const { user } = useAuth();

  const canDeleteRepair = user.userLevel <= 1;
  const canEditRepair = user.userLevel <= 1;

  const modalRef = useRef(null);

  const formattedDate = new Date(date).toLocaleDateString("en-GB");
  const isDestination = prevLoca
    ? { color: "rgb(53, 178, 53)" }
    : { color: "white" };

    const handleDeleteHistoryElement = async (id, motorbikeId, setBikeData) => {
      try {
          // Delete history event
          const historyResponse = await fetch(`/api/motorbikes/${motorbikeId}/history/${id.toString()}`, {
              method: "DELETE",
          });
          if (!historyResponse.ok) throw new Error("Failed to delete history event");
  
          // Conditionally delete invoice
          if (invoiceId) {
              const invoiceResponse = await axios.delete(`/api/invoices/${invoiceId.toString()}`);
              if (invoiceResponse.status !== 200) throw new Error("Failed to delete invoice");
          }
  
          // Conditionally delete image
          const formData = new FormData();
          formData.append("type", "event");
          const eventId = id;
          const bikeId = bikeRegNumber;
          formData.append("id", JSON.stringify({ bikeId, eventId }));
          const imageResponse = await axios.post("/api/delete-image", formData);
          if (imageResponse.status !== 200) throw new Error("Failed to delete image");
  
          // Update state
          setBikeData((prevData) => ({
              ...prevData,
              historical_events: prevData.historical_events.filter((event) => event.id !== id),
          }));
          console.log("History event deleted successfully");
      } catch (error) {
          console.error("Error:", error);
      }
  };
  

  return (
    <div className="history_concrete_card_container">
      {imgpath ? (
        <img src={imgpath} alt="History" />
      ) : (
        <div className="history_card_img_placeholder">
          <p style={{ textAlign: "center", fontSize: "20px", color: "white" }}>
            Manglende <br /> billede
          </p>
        </div>
      )}
      <div className="history_concrete_card_data_container">
        <div className="history_concrete_card_data_header">
          <div className="history_concrete_card_data_header_data">
            <p>{header}</p>
            <p>
              {prevLoca && (
                <>
                  <p id="hcard_fix_prevloca" className="locafixhcard">
                    {prevLoca}
                  </p>{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: "15px",
                    }}
                  />
                </>
              )}
              <p style={isDestination} className="locafixhcard">
                {loca}
              </p>
            </p>
          </div>
          <div className="history_concrete_card_data_header_date">
            {type === "Reparation" &&
              canEditRepair && ( // Only render edit button for 'Reparation' events
                <div className="edit_survey">
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => {
                      onEdit({
                        id,
                        title: header,
                        description,
                        date,
                        location: loca,
                        km_driven: currentKM,
                      });
                    }}
                  />
                </div>
              )}
            <p>{currentKM + " km"}</p>
            <p>{formattedDate}</p>
            {canDeleteRepair && (
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: "rgb(178, 53, 53)", cursor: "pointer" }}
                onClick={() => {
                  modalRef.current.openModal();
                }}
              />
            )}
          </div>
        </div>
        <div className="history_concrete_card_data_description">
          <span>{description}</span>
        </div>
      </div>
      <Modal
        ref={modalRef}
        title={"Er du sikker på sletning af " + header + "?"}
      >
        <div id="modal_inner_fix">
          <div>
            <div className="reperation_submit_container">
              <button
                onClick={() => {
                  handleDeleteHistoryElement(id, motorbikeId, setBikeData);
                  modalRef.current.closeModal();
                }}
                style={{ color: "rgb(178, 53, 53)" }}
              >
                SLET HISTORIK-ELEMENT
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HistoryCard;
