import React, { useRef } from 'react';
import './PopUp.css';

const PopUp = ({ isOpen, onClose, onSubmit, title, children }) => {
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="popup-overlay" onClick={handleClickOutside}>
            <div className="popup-content" ref={popupRef}>
                <h2 style={{color:"white",marginBottom:"10px", fontSize:"20px"}}>{title}</h2>
                <div className="popup-actions">
                    {children}
                    <button id="butfix" onClick={onSubmit}>Opdater</button>
                </div>
            </div>
        </div>
    );
};

export default PopUp;
