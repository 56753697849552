import React, { useEffect, useState, useCallback } from 'react';
import './MCCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import defaultImageUrl from '../../assets/lisbethMC.png';

function getColorForProgress(progress) {
    if (progress < 33) return '#FFFFFF';
    else if (progress <= 66) return '#FFC086';
    else return '#ff7067';
}

function getProgressFromKM(km) {
    const upperBound = 25000;
    return (km / upperBound) * 100;
}

const MCCard = ({ gps, s, cardNumber, cardId, km, status, cardDescription, stelNumber }) => {
    const [imageUrl, setImageUrl] = useState(defaultImageUrl);

    const getMCImage = useCallback(async (cardId) => {
        try {
            const response = await axios.get(`/api/mc/get-image/${cardId}`);
            if (response.data.url) {setImageUrl(response.data.url);}
            else {setImageUrl(defaultImageUrl);}
        } catch (error) {
            setImageUrl(defaultImageUrl)
        }
    }, []); // Use empty dependency array to memoize

    useEffect(() => {
        if (cardId) {
            getMCImage(cardId);
        }
    }, [cardId]); // Only refetch when cardId changes

    const gpsIcon = useCallback((gps) => {
        return gps ? "#35b235" : "#b23535";
    }, []);

    const sunken = useCallback((s) => {
        return s ? "flex" : "none";
    }, []);

    const navigate = useNavigate();
    const goToConcreteMC = useCallback(() => navigate(`/main/dashboard/mc/${cardId}`), [navigate, cardId]);

    const mcCardStatusColor = useCallback((status) => {
        const baseColor = '#222534';
        const tint = status === 'AKTIV' ? baseColor : (status === 'INAKTIV' ? 'rgba(178, 157, 53, 0.2)' : 'rgba(178, 53, 53, 0.2)');
        return `linear-gradient(${tint}, ${tint}), ${baseColor}`;
    }, []);

    return (
        <div className="card" onClick={goToConcreteMC} style={{ background: mcCardStatusColor(status) }}>
            <div className="card_header">
                <div className="card_header_container">
                    <div className='card_header_container_identifiers'>
                        <div><p>Nr: </p> <p>{cardNumber}</p></div>
                        <div><p>Reg. nr: </p> <p>{cardId}</p></div>
                        <div className='card_header_field_twoliner'><p>Stel nr: </p> <p>{stelNumber}</p></div>
                        <div className='card_header_field_twoliner'><p>Beskrivelse: </p> <p>{cardDescription}</p></div>
                    </div>
                </div>
                <div className="gps_sunken">
                    <FontAwesomeIcon icon={faLocationDot} className="gps_icon" style={{ color: gpsIcon(gps) }} />
                    <p style={{ display: sunken(s), userSelect: "none" }}><b>S</b></p>
                </div>
            </div>
            <div className="card_image">
                <img src={imageUrl} alt="Bike"onError={() => setImageUrl(defaultImageUrl)} loading="lazy" />
            </div>
            <div className="card_footer">
                <div className="progress_bar_container">
                    <div className="progress_bar"
                        style={{ width: `${getProgressFromKM(km)}%`, backgroundColor: getColorForProgress(getProgressFromKM(km)) }}>
                    </div>
                    <span className="progress_text">{km} km</span>
                </div>
            </div>
        </div>
    );
};

export default MCCard;
