import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../Shared/Modal";
import "./Admin.css";
import "../Dashboard/Dashboard.css";
import "../Dashboard/ConcreteMC.css";
import "../Main.css";
import Header from "../Shared/Header";
import NumberFormat from "react-currency-format";
import { useAuth } from "../../Auth/AuthContext";
import { Navigate } from 'react-router-dom';

const Admin = () => {
  // Base parts
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [baseparts, setBaseparts] = useState([]);

  // Users
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userLevel, setUserLevel] = useState("");
  const [users, setUsers] = useState([]);

  // Trip price
  const [departments, setDepartments] = useState([]);
  const [toEditDepartment, setToEditDepartment] = useState(null);
  const [tripPrice, setTripPrice] = useState("");
  const editTripPriceModalRef = useRef(null);

  // Basepart/User deletion
  const [toDeleteBasepart, setToDeleteBasepart] = useState(null);
  const [toDeleteUser, setToDeleteUser] = useState(null);
  const deleteModalRef = useRef(null);

  useEffect(() => {
    fetchUsers();
    fetchBaseparts();
    fetchDepartments();
  }, []);

  // Access Levels
  const { user } = useAuth();

  if (user.userLevel !== 0) {
    return <Navigate to="/" />; // Redirect if not an admin
  }

  /**
   * Department get, edit
   */
  const fetchDepartments = async () => {
    try {
      const response = await axios.get("/api/departments");
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  // Edit trip price
  const requestEditTripPrice = async () => {
    const update = {
      _id: toEditDepartment._id,
      tripPrice: parseFloat(tripPrice),
    };

    try {
      await axios.patch("/api/edit-department", update);
      fetchDepartments();
    } catch (error) {
      console.error("Error editing trip price:", error);
    }
  };

  const handleEditTripPrice = async () => {
    await requestEditTripPrice();
    editTripPriceModalRef.current.closeModal();
    setToEditDepartment(null);
    setTripPrice("");
  };

  /**
   * Base Parts get, add, delete
   */
  const fetchBaseparts = async () => {
    try {
      const response = await axios.get("/api/baseparts");
      setBaseparts(response.data);
    } catch (error) {
      console.error("Error fetching base parts:", error);
    }
  };

  const handleAddBasepart = async (event) => {
    event.preventDefault(); // Prevent default form submission

    if (!description || !price) {
      alert("Description and price are required");
      return;
    }

    try {
      await axios.post("/api/baseparts", {
        description: description,
        price: parseFloat(price),
      });
      setDescription("");
      setPrice("");
      fetchBaseparts();
    } catch (error) {
      console.error("Error adding base part:", error);
    }
  };

  const handleDeleteBasepart = async (id) => {
    try {
      await axios.delete(`/api/baseparts/${id}`);
      fetchBaseparts();
    } catch (error) {
      console.error("Error deleting base part:", error);
    }
  };

  /**
   * Users get, add, delete
   */
  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleAddUser = async (event) => {
    event.preventDefault(); // Prevent default form submission

    if (!username || !password || userLevel === "") {
      alert("Username, password, and user level are required");
      return;
    }

    try {
      await axios.post("/api/users", {
        username,
        password,
        userLevel: parseInt(userLevel),
      });
      setUsername("");
      setPassword("");
      setUserLevel("");
      fetchUsers();
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await axios.delete(`/api/users/${id}`);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleElementDeletion = () => {
    toDeleteUser
      ? handleDeleteUser(toDeleteUser._id)
      : handleDeleteBasepart(toDeleteBasepart._id);
    deleteModalRef.current.closeModal();
    setToDeleteBasepart(null);
    setToDeleteUser(null);
  };

  const deleteElement = toDeleteUser
    ? toDeleteUser.username
    : toDeleteBasepart
    ? toDeleteBasepart.description
    : "elementet";

  return (
    <div className="main_inner">
      <Header title={"Administration"}></Header>
      <div className="admin_container">
        <div>
          <div>
            <h2>Tilføj ny basis del</h2>
            <form onSubmit={handleAddBasepart}>
              <div>
                <input
                  className="search_input"
                  type="text"
                  placeholder="Skriv beskrivelse..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <NumberFormat
                  value={price}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    setPrice(value);
                  }}
                  placeholder="Skriv stk. pris..."
                  className="search_input"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"DKK "}
                  allowNegative={false}
                  isNumericString={true}
                />
              </div>
              <div className="reperation_submit_container">
                <button>Tilføj Del</button>
              </div>
            </form>
          </div>
          <div>
            <h3>Eksisterende Basis Dele</h3>
            <ul>
              <li>
                <div>
                  <b>Beskrivelse</b>
                  <b>Stk. Pris</b>
                  <span></span>
                </div>
                <hr></hr>
              </li>
              {baseparts.map((basepart) => (
                <li key={basepart._id}>
                  <div>
                    <span>{basepart.description}</span>
                    <span>DKK {basepart.price}</span>
                    <div>
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: "rgb(178, 53, 53)", cursor: "pointer" }}
                        onClick={() => {
                          setToDeleteBasepart(basepart);
                          deleteModalRef.current.openModal();
                        }}
                      />
                    </div>
                  </div>
                  <hr></hr>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h2>Tilføj ny bruger</h2>
            <form autocomplete="off" onSubmit={handleAddUser}>
              <div>
                <input
                  className="search_input"
                  type="text"
                  name="brugernavn"
                  autocomplete="new-brugernavn"
                  placeholder="Skriv brugernavn..."
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <input
                  className="search_input"
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  placeholder="Skriv password..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <input
                  className="search_input"
                  type="number"
                  name="bruger-niveau"
                  placeholder="Vælg niveau..."
                  value={userLevel}
                  onChange={(e) => setUserLevel(e.target.value)}
                  required
                />
              </div>
              <div className="reperation_submit_container">
                <button>Tilføj Bruger</button>
              </div>
            </form>
          </div>
          <div>
            <h3>Eksisterende Brugere</h3>
            <ul>
              <li>
                <div>
                  <b>Brugernavn</b>
                  <b>Niveau</b>
                  <span></span>
                </div>
                <hr></hr>
              </li>
              {users.map((user) => (
                <li key={user._id}>
                  <div>
                    <span>{user.username}</span>
                    <span>{user.userLevel}</span>
                    <div>
                      {users.length === 1 ? (
                        ""
                      ) : (
                        <FontAwesomeIcon
                          icon={faXmark}
                          style={{
                            color: "rgb(178, 53, 53)",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setToDeleteUser(user);
                            deleteModalRef.current.openModal();
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <hr></hr>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h3>Eksisterende Ture</h3>
            <ul className="trips_container">
              <li>
                <div>
                  <b>Til</b>
                  <b>Pris</b>
                  <span></span>
                </div>
                <hr></hr>
              </li>
              {departments.map((department) => (
                <li key={department._id}>
                  <div>
                    <span>{department.name}</span>
                    <span>DKK {department.tripPrice}</span>
                    <div
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        padding: "5px",
                      }}
                    >
                      <FontAwesomeIcon
                        className="edit_survey"
                        icon={faPenToSquare}
                        style={{ width: "20px", height: "20px" }}
                        onClick={() => {
                          setTripPrice(department.tripPrice);
                          setToEditDepartment(department);
                          editTripPriceModalRef.current.openModal();
                        }}
                      />
                    </div>
                  </div>
                  <hr></hr>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Modal
        ref={deleteModalRef}
        title={`Er du sikker på sletning af ` + deleteElement + " ?"}
      >
        <div id="modal_inner_fix">
          <div>
            <div className="reperation_submit_container">
              <button
                onClick={() => {
                  handleElementDeletion();
                }}
                style={{ color: "rgb(178, 53, 53)" }}
              >
                SLET {deleteElement}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal ref={editTripPriceModalRef} title={"Opdatering af tur pris"}>
        <div id="modal_inner_fix">
          <div>
            <div className="add_rep_price_input">
              <span>
                {"Tur Pris (" +
                  (toEditDepartment ? toEditDepartment.name : "") +
                  ")"}
              </span>
              <NumberFormat
                value={tripPrice}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  setTripPrice(value);
                }}
                placeholder="Skriv tur pris..."
                className="search_input"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"DKK "}
                allowNegative={false}
                isNumericString={true}
              />
            </div>
            <div className="reperation_submit_container">
              <button
                onClick={() => {
                  handleEditTripPrice();
                }}
                style={{ color: "rgb(178, 157, 53)" }}
              >
                OPDATÉR TUR
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Admin;
