import React from "react";
import "./Navbar.css";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar, faListCheck, faMotorcycle, faScrewdriverWrench, faUser } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  return (
    <div id="navbar">
      <NavbarContent />
      <NavbarBottom />
    </div>
  );
}

function NavbarContent() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const goToDashboard = () => navigate("/main/dashboard");
  const goToTasks = () => navigate("/main/tasks");
  const goToInvoices = () => navigate("/main/invoices");
  const goToAdmin = () => navigate("/main/admin");

  return (
    <div id="navbar_content">
      <div id="navbar_content_header">Lisbeth's Køreskole</div>
      <div id="navbar_content_menu">
        {user && user.userLevel <= 3 && (
          <li onClick={goToDashboard}>
            <FontAwesomeIcon style={{ color: "white", width: "35px", height: "35px" }} icon={faMotorcycle} />
            <p>Oversigt</p>
          </li>
        )}
        <li onClick={goToTasks}>
          <FontAwesomeIcon style={{ color: "white", width: "35px", height: "35px" }} icon={faListCheck} />
          <p>Opgaver</p>
        </li>
        {user && user.userLevel <= 2 && (
          <li onClick={goToInvoices}>
            <FontAwesomeIcon style={{ color: "white", width: "35px", height: "35px" }} icon={faFileInvoiceDollar} />
            <p>Fakturaer</p>
          </li>
        )}
        {user && user.userLevel === 0 && (
          <li onClick={goToAdmin}>
            <FontAwesomeIcon style={{ color: "white", width: "35px", height: "35px" }} icon={faScrewdriverWrench} />
            <p>Administration</p>
          </li>
        )}
      </div>
    </div>
  );
}

function NavbarBottom() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div id="navbar_bottom">
      <FontAwesomeIcon style={{ color: "white", width: "65px", height: "65px" }} icon={faUser} />
      <div id="navbar_bottom_data">
        <div id="navbar_bottom_data_user">
          <p>{user ? user.username : "Guest"}</p>
          <p>{user ? getUserRole(user.userLevel) : ""}</p>
        </div>
        <button onClick={handleLogout}>Log ud</button>
      </div>
    </div>
  );
}

function getUserRole(userLevel) {
  switch (userLevel) {
    case 0:
      return "Admin";
    case 1:
      return "Mekaniker";
    case 2:
      return "Afdelingsleder";
    case 3:
      return "Kørelærer";
    default:
      return "Ukendt Role";
  }
}

export default Navbar;
