import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './Routing/Router';
import { AuthProvider } from './Auth/AuthContext'; // Import the AuthProvider
import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider> {/* Wrap the entire app with AuthProvider */}
            <AppRouter />
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
